import { configureStore } from "@reduxjs/toolkit";
// import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import LoadingReducer from "./slices/loading/loading.slice";
import LoginReducer from "./slices/login/login.slice";
import AuthReducer from "./slices/auth/auth.slice";
import JobReducer from "./slices/job/job.slice";
import SavedJobReducer from "./slices/saved-job/saved-job.slice";
import CategoryReducer from "./slices/category/category.slice";
import UserReducer from "./slices/user/user.slice";
import ApplicationReducer from "./slices/application/application.slice";

export const store = configureStore({
  reducer: {
    loadingReducer: LoadingReducer,
    loginReducer: LoginReducer,
    authReducer: AuthReducer,
    jobReducer: JobReducer,
    savedJobReducer: SavedJobReducer,
    categoryReducer: CategoryReducer,
    userReducer: UserReducer,
    applicationReducer: ApplicationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;
