import React, { useState, useEffect } from "react";

const MessageDialog = (props: any) => {
  const [buttonStyle, setButtonStyle] = useState(
    "button is-danger is-small margin-top-20"
  );
  const closeDialog = () => {
    props.closeDialogHandler();
  };

  useEffect(() => {
    if (props.isSuccess) {
      setButtonStyle("button is-success is-small margin-top-20");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="custom-active-modal">
        <div className="custom-modal-background"></div>
        <div className="modal-content no-scroll">
          <div className="modal-content-border">
            <div className="modal-content-wrapper">
              <div
                className="message-content"
                dangerouslySetInnerHTML={{ __html: props.message }}
              />
              <button className={buttonStyle} onClick={closeDialog}>
                close
              </button>
            </div>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={closeDialog}
        ></button>
      </div>
    </>
  );
};

export default MessageDialog;
