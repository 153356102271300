import React from "react";
import ReactDOM from "react-dom";
import "bulma/css/bulma.min.css";
import App from "./App";
import { store } from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { JobSeekerAuthService } from "./services/auth/jobseeker-auth-service";
import { JobSeekerAuthProvider } from "./store/context/auth/auth.provider";

const authService = new JobSeekerAuthService({
  clientId: `${process.env.REACT_APP_AWS_COG_CLIENTID}`,
  location: window.location,
  strLocation: `${process.env.REACT_APP_WEBAPP_BASE_URL}/check-login`,
  provider: `${process.env.REACT_APP_AUTH_PROVIDER_BASE_URL}`,
  redirectUri: `${process.env.REACT_APP_WEBAPP_BASE_URL}/check-login`,
  scopes: ["openid", "profile", `${process.env.REACT_APP_CUSTOM_SCOPE}`],
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <JobSeekerAuthProvider authService={authService}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </JobSeekerAuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
