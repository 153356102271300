import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthSliceModel } from "./auth.slice.model";
import { AppInitialState } from "../../app.initial.state";

const initialState: AuthSliceModel = AppInitialState.authModel;

export const AuthSlice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<any>) => {
      state.auth = action.payload;
    },
  },
});

export const { setAuth } = AuthSlice.actions;

export default AuthSlice.reducer;
