import React, { useState, useEffect } from "react";
import { Icon } from "ts-react-feather-icons";

import "./header.component.style.scss";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const HeaderComponent = () => {
  const [openMe, setOpenMe] = useState(false);
  const { width } = useWindowDimensions();

  const pressMe = () => {
    if (openMe) {
      document.body.classList.remove("sidenav-toggled");
      setOpenMe(false);
    } else {
      document.body.classList.add("sidenav-toggled");
      setOpenMe(true);
    }
  };

  useEffect(() => {
    if (width < 641) {
      document.body.classList.add("mobile-view");
    } else {
      document.body.classList.remove("mobile-view");
    }
  }, [width]);

  return (
    <>
      <div className="main-header">
        <div className="container-fluid">
          <div className="main-header-left">
            <div className="app-sidebar__toggle" onClick={() => pressMe()}>
              <button className="open-toggle the-hand">
                <Icon name="align-left" color="gray" size={24} />
              </button>
              <button className="close-toggle the-hand">
                <Icon name="chevrons-right" color="gray" size={24} />
              </button>
            </div>
          </div>
          <div className="main-header-right"></div>
        </div>
      </div>
      <div className="jumps-prevent" style={{ paddingTop: "63px" }}></div>
    </>
  );
};

export default HeaderComponent;
