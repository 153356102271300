import axios from "../helper/axios.helper";

class ApplicationAPIService {
  configGet() {
    const token = JSON.parse(
      JSON.parse(JSON.stringify(window.localStorage.getItem("auth")))
    );

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `${token.access_token}`,
        Accept: "application/json",
      },
    };

    return config;
  }

  configPost() {
    const token = JSON.parse(
      JSON.parse(JSON.stringify(window.localStorage.getItem("auth")))
    );

    const configPost = {
      headers: {
        Authorization: `${token.access_token}`,
      },
    };

    return configPost;
  }

  getAllApplications() {
    return axios.get("/applications", this.configGet());
  }

  //   createCategory(categoryName: string) {
  //     return axios.post(
  //       "/category",
  //       {
  //         Name: categoryName,
  //       },
  //       this.configPost()
  //     );
  //   }
}

export default new ApplicationAPIService();
