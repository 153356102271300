import { Outlet } from "react-router-dom";
import LoadingComponent from "../loading/loading.component";

import "./layout.noheader.scss";
import Logo from "../../../../src/assets/csjobseeker_logo.png";

const LayoutCustomHeaderComponent = () => {
  return (
    <>
      <div className="logo-container">
        <img src={Logo} alt="Logo" />
        <div className="logo-title">CS Job Seeker</div>
      </div>
      <LoadingComponent />
      <Outlet />
    </>
  );
};

export default LayoutCustomHeaderComponent;
