import React from "react";
import JobService from "../../services/job/job.service";
import { useEffect, useState } from "react";
import BreadCrumbsComponent from "../../common/components/breadcrumbs/breadcrumbs.component";
import JobListingsPending from "./job-listing-pending.component";
import JobListingsAccepted from "./job-listing-accepted.compnent";
import JobListingsDeclined from "./job-listing-declined.component";
import JobListingsExpired from "./job-listing-expired.component";
import {
  fetchJobs,
  fetchJobsSuccess,
  fetchJobsFail,
} from "../../store/slices/job/job.slice";
import { show, hide } from "../../store/slices/loading/loading.slice";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import JobDetailsComponent from "../../common/components/job-details/job-details.component";

import "./job-listings.style.scss";

const JobListingsPage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [jobDetailContent, setJobDetailContent] = useState({});
  const [searchKeyTerms, setSearchKeyTerms] = useState("");

  const dispatch = useAppDispatch();
  const isFetchingJobs = useAppSelector(
    (state) => state.jobReducer.isFetchingJobs
  );
  const allJobs = useAppSelector((state) => state.jobReducer.jobs);

  const pendingJobs = allJobs.filter((job: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const closingDate = new Date(job.JobApplicationEndDate);
    let found = false;
    if (searchKeyTerms === "") {
      found = true;
    } else if (
      job.JobRole.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase())
    ) {
      found = true;
    }
    return job.Status === "Pending" && closingDate >= today && found;
  });

  const acceptedJobs = allJobs.filter((job: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const closingDate = new Date(job.JobApplicationEndDate);
    let found = false;
    if (searchKeyTerms === "") {
      found = true;
    } else if (
      job.JobRole.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase())
    ) {
      found = true;
    }
    return job.Status === "Accepted" && closingDate >= today && found;
  });

  const declinedJobs = allJobs.filter((job: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const closingDate = new Date(job.JobApplicationEndDate);
    let found = false;
    if (searchKeyTerms === "") {
      found = true;
    } else if (
      job.JobRole.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase())
    ) {
      found = true;
    }
    return job.Status === "Declined" && closingDate >= today && found;
  });

  const closedJobs = allJobs.filter((job: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const closingDate = new Date(job.JobApplicationEndDate);
    let found = false;
    if (searchKeyTerms === "") {
      found = true;
    } else if (
      job.JobRole.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase())
    ) {
      found = true;
    }
    return closingDate < today && found;
  });

  useEffect(() => {
    // console.log(allJobs);
    if (!isFetchingJobs) {
      dispatch(fetchJobs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingJobs) {
      dispatch(show());
      JobService.getAllJobs()
        .then((jobs) => {
          dispatch(fetchJobsSuccess(jobs.data));
          dispatch(hide());
        })
        .catch((error) => {
          dispatch(fetchJobsFail(error));
          dispatch(hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingJobs]);

  const showDetails = (job: any) => {
    setShowDialog(true);
    setJobDetailContent(job);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const tabItem = () => {
    switch (activeTab) {
      case 1:
        return (
          <JobListingsPending
            jobs={pendingJobs}
            showDetailHandler={showDetails}
          />
        );
      case 2:
        return (
          <JobListingsAccepted
            jobs={acceptedJobs}
            showDetailHandler={showDetails}
          />
        );
      case 3:
        return (
          <JobListingsDeclined
            jobs={declinedJobs}
            showDetailHandler={showDetails}
          />
        );
      case 4:
        return (
          <JobListingsExpired
            jobs={closedJobs}
            showDetailHandler={showDetails}
          />
        );
      default:
        return <JobListingsPending jobs={pendingJobs} />;
    }
  };

  const ignoreDefaultEvent = (e: any) => {
    e.preventDefault();
  };

  const currentPageTitlle = "Job Listings";

  return (
    <div className="container custom-layout">
      {showDialog && (
        <JobDetailsComponent
          closeDialogHandler={closeDialog}
          jobDetail={jobDetailContent}
        />
      )}

      <div className="subheader-layout">
        <BreadCrumbsComponent
          mainCrumb="Job Management"
          subCrumb={currentPageTitlle}
        />
      </div>
      <div className="card">
        <div className="card-header header-spacer no-border-section">
          <h1 className="page-sub-heading">{currentPageTitlle}</h1>
        </div>
        <div className="card-header header-spacer">
          <div className="columns">
            <div className="column is-half">
              <div className="search-bar">
                <input
                  name="searchKey"
                  type="text"
                  className="input"
                  placeholder="Search Jobs"
                  value={searchKeyTerms}
                  onChange={(e) => setSearchKeyTerms(e.target.value)}
                />
              </div>
            </div>
            <div className="column">
              <div className="tabs is-bigger is-right">
                <ul>
                  <li
                    className={activeTab === 1 ? "is-active" : ""}
                    onClick={() => setActiveTab(1)}
                  >
                    <a href="\#" onClick={ignoreDefaultEvent}>
                      Pending
                    </a>
                  </li>
                  <li
                    className={activeTab === 2 ? "is-active" : ""}
                    onClick={() => setActiveTab(2)}
                  >
                    <a href="\#" onClick={ignoreDefaultEvent}>
                      Accepted
                    </a>
                  </li>
                  <li
                    className={activeTab === 3 ? "is-active" : ""}
                    onClick={() => setActiveTab(3)}
                  >
                    <a href="\#" onClick={ignoreDefaultEvent}>
                      Declined
                    </a>
                  </li>
                  <li
                    className={activeTab === 4 ? "is-active" : ""}
                    onClick={() => setActiveTab(4)}
                  >
                    <a href="/#" onClick={ignoreDefaultEvent}>
                      Expired/Closed
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body body-spacer">{tabItem()}</div>
      </div>
    </div>
  );
};

export default JobListingsPage;
