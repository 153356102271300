import { useState, useEffect } from "react";
import BreadCrumbsComponent from "../../common/components/breadcrumbs/breadcrumbs.component";
import { show, hide } from "../../store/slices/loading/loading.slice";
import { useAppDispatch } from "../../store/hooks";
import UserService from "../../services/user/user.service";
import { useForm } from "react-hook-form";

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const [userNameVar, setUserNameVar] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const submitHandler = (data: any) => {
    dispatch(show());
    UserService.updateUser({
      UserName: userNameVar,
      FirstName: data.FirstName,
      LastName: data.LastName,
      Position: data.Position,
      Department: data.Department,
      Address1: data.Address1,
      Address2: data.Address2,
      Phone: data.Phone,
    })
      .then((res) => {
        dispatch(hide());
      })
      .catch((error) => {
        dispatch(hide());
      });
  };

  useEffect(() => {
    dispatch(show());
    const userData: string = window.localStorage.getItem("userData") || "";
    const userDataItem = JSON.parse(userData);
    setUserNameVar(userDataItem.userName);
    UserService.getUserByUsername(userDataItem.userName).then(
      (userDataItems) => {
        console.log(userDataItems);

        const {
          FirstName,
          LastName,
          Position,
          Department,
          Address1,
          Address2,
          Phone,
        } = userDataItems.data.Items[0];

        setValue("FirstName", FirstName);
        setValue("LastName", LastName);
        setValue("Position", Position);
        setValue("Department", Department ? Department : "");
        setValue("Address1", Address1 ? Address1 : "");
        setValue("Address2", Address2 ? Address2 : "");
        setValue("Phone", Phone ? Phone : "");

        dispatch(hide());
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container custom-layout">
      <div className="subheader-layout">
        <BreadCrumbsComponent mainCrumb="Settings" subCrumb="My Profile" />
      </div>
      <div className="card">
        <div className="card-header header-spacer no-border-section">
          <h1>My Profile</h1>
        </div>
        <div className="card-body body-spacer">
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="form-section">
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="FirstName"
                        {...register("FirstName", { required: true })}
                      />
                      {errors.FirstName && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="LastName"
                        {...register("LastName", { required: true })}
                      />
                      {errors.LastName && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Position"
                        {...register("Position", { required: true })}
                      />
                      {errors.Position && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Department"
                        {...register("Department", { required: true })}
                      />
                      {errors.Department && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Address1"
                        {...register("Address1", { required: true })}
                      />
                      {errors.Address1 && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Address2"
                        {...register("Address2", { required: true })}
                      />
                      {errors.Address2 && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Phone"
                        {...register("Phone", { required: true })}
                      />
                      {errors.Phone && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="submit-button-section">
                <button className="button is-primary">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
