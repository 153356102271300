import axios from "../helper/axios.helper";

class UserAPIService {
  configGet() {
    const token = JSON.parse(
      JSON.parse(JSON.stringify(window.localStorage.getItem("auth")))
    );

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `${token.access_token}`,
        Accept: "application/json",
      },
    };

    return config;
  }

  configPost() {
    const token = JSON.parse(
      JSON.parse(JSON.stringify(window.localStorage.getItem("auth")))
    );

    const configPost = {
      headers: {
        Authorization: `${token.access_token}`,
      },
    };

    return configPost;
  }

  getAllUsers() {
    return axios.get("/users", this.configGet());
  }

  getUserByUsername(UserName: string) {
    return axios.post(
      "/user-by-username",
      {
        UserName,
      },
      this.configPost()
    );
  }

  updateUser(userData: any) {
    return axios.post(
      "/update-user",
      {
        UserName: userData.UserName,
        FirstName: userData.FirstName,
        LastName: userData.LastName,
        Position: userData.Position,
        Department: userData.Department,
        Address1: userData.Address1,
        Address2: userData.Address2,
        Phone: userData.Phone,
      },
      this.configPost()
    );
  }

  changeUserRole(
    userName: string,
    position: string,
    role: string,
    roleId: string
  ) {
    return axios.post(
      "/make-admin",
      {
        UserName: userName,
        Position: position,
        Role: role,
        RoleId: roleId,
      },
      this.configPost()
    );
  }
}

export default new UserAPIService();
