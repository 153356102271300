import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategorySliceModel } from "./category.slice.model";
import { AppInitialState } from "../../app.initial.state";

const initialState: CategorySliceModel = AppInitialState.categoryModel;

export const CategorySlice = createSlice({
  name: "categoryReducer",
  initialState,
  reducers: {
    fetchCategories: (state) => {
      state.isFetchingCategories = true;
    },
    fetchCategoriesSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
      state.isFetchingCategories = false;
      state.categories = action.payload;
    },
    fetchCategoriesFail: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isFetchingCategories = false;
    },
  },
});

export const { fetchCategories, fetchCategoriesSuccess, fetchCategoriesFail } =
  CategorySlice.actions;

export default CategorySlice.reducer;
