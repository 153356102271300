const JobDetailsComponent = (props: any) => {
  const closeDialog = () => {
    props.closeDialogHandler();
  };

  const showApplyURL = () => {
    const showApply = props.jobDetail.hasOwnProperty("ApplyLink")
      ? props.jobDetail.ApplyLink
      : false;

    if (showApply) {
      return (
        <div>
          Click the following link:{" "}
          <a href={props.jobDetail.ApplyLinkURL}>
            {props.jobDetail.ApplyLinkURL}
          </a>
        </div>
      );
    } else {
      return (
        <div>
          Email your CV and Cover letter to the email provided in the Contact
          Information section above.
        </div>
      );
    }
  };

  const showContactInfo = () => {
    const showCI: boolean = props.jobDetail.hasOwnProperty(
      "ShowContactInformation"
    )
      ? props.jobDetail.ShowContactInformation
      : false;

    if (showCI) {
      return (
        <div className="modal-row dashed-border">
          {/* Begin coloumns */}
          <div className="columns detail-columns">
            <div className="column detail-header">Contact Information</div>
            <div className="column detail-header"></div>
          </div>
          {/* End coloumns */}
          {/* Begin coloumns */}
          <div className="columns detail-columns">
            <div className="column">
              <div className="detail-label">Contact Person Name</div>
              <div className="detail-value">
                {props.jobDetail.CompanyContactName}
              </div>
            </div>
            <div className="column">
              <div className="detail-label">Email</div>
              <div className="detail-value">
                {props.jobDetail.CompanyContactEmail}
              </div>
            </div>
          </div>
          {/* End coloumns */}
          {/* Begin coloumns */}
          <div className="columns detail-columns">
            <div className="column">
              <div className="detail-label">Contact Number</div>
              <div className="detail-value">
                {props.jobDetail.CompanyContactNumber}
              </div>
            </div>
            <div className="column">
              <div className="detail-label">Website</div>
              <div className="detail-value">
                {props.jobDetail.CompanyWebsite}
              </div>
            </div>
          </div>
          {/* End coloumns */}
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className="custom-active-modal">
        <div className="custom-modal-background"></div>
        <div className="modal-content">
          <div className="modal-content-border">
            {/* Begin modal-row */}
            <div className="modal-row dashed-border">
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column detail-header">
                  Job / Role Information
                </div>
                <div className="column detail-header"></div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column">
                  <div className="detail-label">Job/Role Title</div>
                  <div className="detail-value">{props.jobDetail.JobRole}</div>
                </div>
                <div className="column">
                  <div className="detail-label">Category</div>
                  <div className="detail-value">
                    {props.jobDetail.JobCategory}
                  </div>
                </div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns expand-column-styled">
                <div className="column">
                  <div className="detail-label">Job Description</div>
                  <div className="detail-value">
                    {props.jobDetail.JobDescription}
                  </div>
                </div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column">
                  <div className="detail-label">Minimum Qualification</div>
                  <div className="detail-value">
                    {props.jobDetail.JobMinimumQualification}
                  </div>
                </div>
                <div className="column">
                  <div className="detail-label">Empoyment Type</div>
                  <div className="detail-value">
                    {props.jobDetail.JobPayType}
                  </div>
                </div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column">
                  <div className="detail-label">Work Hours</div>
                  <div className="detail-value">
                    {props.jobDetail.JobWorkType}
                  </div>
                </div>
                <div className="column">
                  <div className="detail-label">
                    Maximum hours per week can partime
                  </div>
                  <div className="detail-value">
                    {props.jobDetail.MaxHoursPartime}
                  </div>
                </div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column">
                  <div className="detail-label">Key Skills</div>
                  <div className="detail-value">
                    {props.jobDetail.JobKeySkills}
                  </div>
                </div>
                <div className="column">
                  <div className="detail-label">Workplace</div>
                  <div className="detail-value">
                    {props.jobDetail.JobWorkplace}
                  </div>
                </div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns expand-column-styled">
                <div className="column">
                  <div className="detail-label">Referral</div>
                  <div className="detail-value">{props.jobDetail.Referral}</div>
                </div>
              </div>
              {/* End coloumns */}
            </div>
            {/* End modal-row */}
            {/* Begin modal-row */}
            <div className="modal-row dashed-border">
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column detail-header">Company Information</div>
                <div className="column detail-header"></div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column">
                  <div className="detail-label">Company</div>
                  <div className="detail-value">{props.jobDetail.Company}</div>
                </div>
                <div className="column">
                  <div className="detail-label"></div>
                  <div className="detail-value"></div>
                </div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns expand-column-styled">
                <div className="column">
                  <div className="detail-label">Company Description</div>
                  <div className="detail-value">
                    {props.jobDetail.CompanyDescription}
                  </div>
                </div>
              </div>
              {/* End coloumns */}
            </div>
            {/* End modal-row */}

            {/* Begin modal-row */}
            {showContactInfo()}
            {/* End modal-row */}

            {/* Begin modal-row */}
            <div className="modal-row">
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column detail-header">Dates and Location</div>
                <div className="column detail-header"></div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column">
                  <div className="detail-label">Application Start Date</div>
                  <div className="detail-value">
                    {" "}
                    {props.jobDetail.JobApplicationStartDate}
                  </div>
                </div>
                <div className="column">
                  <div className="detail-label">Application Close Date</div>
                  <div className="detail-value">
                    {props.jobDetail.JobApplicationEndDate}
                  </div>
                </div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column">
                  <div className="detail-label">Location</div>
                  <div className="detail-value">
                    {props.jobDetail.JobLocation}
                  </div>
                </div>
                <div className="column">
                  <div className="detail-label"></div>
                  <div className="detail-value"></div>
                </div>
              </div>
              {/* End coloumns */}
            </div>
            {/* End modal-row */}
            {/* Begin modal-row */}
            <div className="modal-row">
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column detail-header">To Apply</div>
                <div className="column detail-header"></div>
              </div>
              {/* End coloumns */}
              {/* Begin coloumns */}
              <div className="columns detail-columns">
                <div className="column">
                  <div className="detail-value">{showApplyURL()}</div>
                </div>
              </div>
              {/* End coloumns */}
            </div>
            {/* End modal-row */}
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={closeDialog}
        ></button>
      </div>
    </>
  );
};

export default JobDetailsComponent;
