import { createSlice } from "@reduxjs/toolkit";
import { LoadingSliceModel } from "./loading.slice.model";
import { AppInitialState } from "../../app.initial.state";

const initialState: LoadingSliceModel = AppInitialState.loadingModel;

export const LoadingSlice = createSlice({
  name: "loadingReducer",
  initialState,
  reducers: {
    show: (state) => {
      state.show = true;
    },
    hide: (state) => {
      state.show = false;
    },
  },
});

export const { show, hide } = LoadingSlice.actions;

export default LoadingSlice.reducer;
