import React from "react";
import JobService from "../../services/job/job.service";

import {
  fetchJobsSuccess,
  fetchJobsFail,
} from "../../store/slices/job/job.slice";
import { show, hide } from "../../store/slices/loading/loading.slice";

import { useAppDispatch } from "../../store/hooks";

const JobListingsDeclined = (props: any) => {
  const dispatch = useAppDispatch();

  const updateJobStatus = (pk: string, sk: string, newStatus: string) => {
    const pkArray = pk.split("#");
    const skArray = sk.split("#");
    dispatch(show());
    JobService.updateJobs(pkArray[1], skArray[1], newStatus)
      .then((res: any) => {
        if (res.status === 200) {
          JobService.getAllJobs()
            .then((jobs) => {
              dispatch(fetchJobsSuccess(jobs.data));
              dispatch(hide());
            })
            .catch((error) => {
              dispatch(fetchJobsFail(error));
              dispatch(hide());
            });
        } else {
          dispatch(hide());
        }
      })
      .catch((error) => {
        dispatch(hide());
      });
  };

  const showDetails = (job: string) => {
    props.showDetailHandler(job);
  };

  return (
    <>
      <div className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>
                <abbr title="Position">Position</abbr>
              </th>
              <th>
                <abbr title="Company">Company</abbr>
              </th>
              <th>
                <abbr title="Employment Type">Employment Type</abbr>
              </th>
              <th>
                <abbr title="Closing Date">Closing</abbr>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {props.jobs.map((job, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div
                      onClick={() => showDetails(job)}
                      className="detail-hand-cursor"
                    >
                      {job.JobRole}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => showDetails(job)}
                      className="detail-hand-cursor"
                    >
                      {job.Company}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => showDetails(job)}
                      className="detail-hand-cursor"
                    >
                      {job.JobWorkType}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => showDetails(job)}
                      className="detail-hand-cursor"
                    >
                      {job.JobApplicationEndDate}
                    </div>
                  </td>
                  <td>
                    <button
                      className="button is-success is-small"
                      onClick={() =>
                        updateJobStatus(job.PK, job.SK, "Accepted")
                      }
                    >
                      Accept
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default JobListingsDeclined;
