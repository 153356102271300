import "./navigation.component.style.scss";
import JobSeekerLogo from "../../../assets/jobseeker_logo.png";
import JobSeekerLogoNoText from "../../../assets/jobseeker_logo_notext.png";
import ProfileImage from "../../../assets/profile_default_crop.jpg";
import { useAppSelector } from "../../../store/hooks";
import NavigationAdminComponent from "./navigation-admin.component";
import NavigationStudentComponent from "./navigation-student.component";

const NavigationComponent = () => {
  const authRedux = useAppSelector((state) => state.authReducer.auth);
  const currentRole = authRedux?.roles ? authRedux.roles : ["student"];

  return (
    <aside className="app-sidebar">
      <div className="app-sidebar-header">
        <img src={JobSeekerLogo} className="jobseeker-logo" alt="" />
        <img
          src={JobSeekerLogoNoText}
          className="jobseeker-logo-notext"
          alt=""
        />
      </div>

      <div className="app-sidebar-navigation">
        <div className="profile-image-outer">
          <img src={ProfileImage} alt="" />
        </div>
        <div className="profile-details">
          <div className="profile-name">
            {authRedux?.user.firstName} {authRedux?.user.lastName}
          </div>
          <div className="profile-position">{authRedux?.user.position}</div>
        </div>
      </div>

      {currentRole?.find((role) => role === "admin") ? (
        <NavigationAdminComponent />
      ) : (
        <NavigationStudentComponent />
      )}
    </aside>
  );
};

export default NavigationComponent;
