import { Outlet } from "react-router-dom";
import "./layout.noheader.scss";
const LayoutNaDahComponent = () => {
  return (
    <>
      <div className="container-main">
        <Outlet />
      </div>
    </>
  );
};

export default LayoutNaDahComponent;
