import { useState, useEffect } from "react";
import BreadCrumbsComponent from "../../common/components/breadcrumbs/breadcrumbs.component";
import { show, hide } from "../../store/slices/loading/loading.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import UserService from "../../services/user/user.service";
import {
    fetchUsers,
    fetchUsersSuccess,
    fetchUsersFail,
} from "../../store/slices/user/user.slice";

import ReactPaginate from "react-paginate";
import * as FaIcons from "react-icons/fa";

const UserListPage = () => {  
  const dispatch = useAppDispatch();
  const isFetchingUsers = useAppSelector(
    (state) => state.userReducer.isFetchingUsers
  );

  const currentUser = useAppSelector(
    (state) => state.authReducer.auth?.user.userName
  );
  
  const allUsers = useAppSelector(
    (state) => state.userReducer.users
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [searchKeyTerms, setSearchKeyTerms] = useState("");

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;
  

  const allFilteredUsers = allUsers.filter((user: any) => {
    let found = false;    
    if(searchKeyTerms === "") {
      found = true;
    } else if(user.FirstName.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase()) || user.LastName.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase())) {
      found = true;
    }
    return user.UserName !== currentUser  && found;
  })

  const pageCount = Math.ceil(allFilteredUsers.length / itemsPerPage);

  const changeRole = (userName:string, position:string, role: string, roleID: string) => {
    dispatch(show());
    UserService.changeUserRole(userName, position, role,roleID)
        .then((result) => {
            UserService.getAllUsers()
                .then((user) => {
                    console.log(user.data.Items);
                dispatch(fetchUsersSuccess(user.data.Items));
                dispatch(hide());
                })
                .catch((error) => {
                dispatch(fetchUsersFail(error));
                dispatch(hide());
                });
            })
            .catch((error) => {
                dispatch(hide());
            });
  }  

  const displayItems = allFilteredUsers
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.FirstName} {item.LastName}</td>
          <td>{item.Role}</td>
          <td>
            { (item.Role === "admin") &&  
                <button
                    className="button is-success is-small"
                    onClick={() =>
                        changeRole(item.UserName, item.Position, "student", "2063b7ef-c964-47d8-9f99-427c11790126")
                    }
                >
                    Change to Student
                    </button>
            }

            { (item.Role === "student") &&  
                <button
                    className="button is-info is-small"
                    onClick={() =>
                        changeRole(item.UserName, item.Position, "admin", "0dd8bca4-b949-49f7-9bb2-f68823a57ca7")
                    }
                >
                    Change to Admin
                    </button>
            }
            
          </td>
        </tr>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


  useEffect(() => {
    if (!isFetchingUsers) {
      dispatch(fetchUsers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingUsers) {
      dispatch(show());
      UserService.getAllUsers()
        .then((user) => {
          dispatch(fetchUsersSuccess(user.data.Items));
          dispatch(hide());
        })
        .catch((error) => {
          dispatch(fetchUsersFail(error));
          dispatch(hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingUsers]);

  return (
    <div className="container custom-layout">
      <div className="subheader-layout">
        <BreadCrumbsComponent mainCrumb="Settings" subCrumb="Users Lists" />
      </div>
      <div className="card">
        <div className="card-header header-spacer no-border-section">
          <h1>User Lists</h1>
        </div>
        <div className="card-body body-spacer">
          <div className="category-list">
          <div className="search-bar">
                  <input
                      name="searchKey"
                      type="text"
                      className="input"
                      placeholder="Search Users"
                      value={searchKeyTerms}
                      onChange={(e) => setSearchKeyTerms(e.target.value)}
                    />              
              </div>
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{displayItems}</tbody>
            </table>
          </div>
          <nav className="pagination" role="navigation" aria-label="pagination">
            <ReactPaginate
              previousLabel={
                <FaIcons.FaChevronLeft className="pagination-previous" />
              }
              nextLabel={<FaIcons.FaChevronRight className="pagination-next" />}
              pageCount={pageCount}
              onPageChange={changePage}
              pageClassName={"pagination-link"}
              containerClassName={"pagination-list"}
              previousLinkClassName={"previous-btns"}
              nextLinkClassName={"next-btns"}
              disabledClassName={"pagination-disabled"}
              activeClassName={"is-current"}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default UserListPage;
