import { Outlet } from "react-router-dom";
import NavigationComponent from "../navigation/navigation.component";
import HeaderComponent from "../header/header.component";
import LoadingComponent from "../loading/loading.component";

const LayoutHeaderComponent = () => {
  return (
    <>
      <NavigationComponent />
      <LoadingComponent />
      <div className="main-container">
        <HeaderComponent />
        <Outlet />
      </div>
    </>
  );
};

export default LayoutHeaderComponent;
