import { useState, useEffect } from "react";
import BreadCrumbsComponent from "../../common/components/breadcrumbs/breadcrumbs.component";
import JobService from "../../services/job/job.service";
import {
  fetchJobs,
  fetchJobsSuccess,
  fetchJobsFail,
} from "../../store/slices/job/job.slice";
import { show, hide } from "../../store/slices/loading/loading.slice";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import JobDetailsComponent from "../../common/components/job-details/job-details.component";

import CurrentJobListingsPage from "./current-job-listings.page";
// import ClosedJobListingsPage from "./closed-job-listings.page";

import "./student-job-listings.style.scss";

const StudentJobListingsPage = () => {
  // const [activeTab, setActiveTab] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [jobDetailContent, setJobDetailContent] = useState({});
  const [searchKeyTerms, setSearchKeyTerms] = useState("");

  const dispatch = useAppDispatch();

  let userID: string =
    useAppSelector((state) => state.authReducer.auth?.user?.userID) || "";
  const isFetchingJobs = useAppSelector(
    (state) => state.jobReducer.isFetchingJobs
  );
  const allJobs = useAppSelector((state) => state.jobReducer.jobs);

  const currentJobs = allJobs.filter((job: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const closingDate = new Date(job.JobApplicationEndDate);
    let found = false;    
    if(searchKeyTerms === "") {
      found = true;
    } else if(job.JobRole.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase())) {
      found = true;
    }
    return job.Status === "Accepted" && closingDate >= today && found;
  });

  // const closedJobs = allJobs.filter((job: any) => {
  //   const today = new Date();
  //   today.setHours(0, 0, 0, 0);
  //   const closingDate = new Date(job.JobApplicationEndDate);
  //   return closingDate < today;
  // });

  const showDetails = (job: any) => {
    setShowDialog(true);
    setJobDetailContent(job);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  // const ignoreDefaultEvent = (e: any) => {
  //   e.preventDefault();
  // };

  // const tabItem = () => {
  //   switch (activeTab) {
  //     case 1:
  //       return (
  //         <CurrentJobListingsPage
  //           jobs={currentJobs}
  //           showDetailHandler={showDetails}
  //         />
  //       );
  //     case 2:
  //       return (
  //         <ClosedJobListingsPage
  //           jobs={closedJobs}
  //           showDetailHandler={showDetails}
  //         />
  //       );
  //     default:
  //       return (
  //         <CurrentJobListingsPage
  //           jobs={currentJobs}
  //           showDetailHandler={showDetails}
  //         />
  //       );
  //   }
  // };

  useEffect(() => {
    if (!isFetchingJobs) {
      dispatch(fetchJobs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingJobs) {
      dispatch(show());
      JobService.getCurrentJobsByUser(userID)
        .then((jobs) => {
          dispatch(fetchJobsSuccess(jobs.data));
          dispatch(hide());
        })
        .catch((error) => {
          dispatch(fetchJobsFail(error));
          dispatch(hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingJobs]);

  const currentPageTitlle = "Current Job Listings";

  return (
    <div className="container custom-layout">
      {showDialog && (
        <JobDetailsComponent
          closeDialogHandler={closeDialog}
          jobDetail={jobDetailContent}
        />
      )}

      <div className="subheader-layout">
        <BreadCrumbsComponent
          mainCrumb="Job Listings"
          subCrumb={currentPageTitlle}
        />
      </div>
      <div className="card">
        <div className="card-header header-spacer no-border-section">
          <h1>{currentPageTitlle}</h1>
        </div>
        <div className="card-header header-spacer">
          <div className="columns">
            <div className="column is-half">
              <div className="search-bar">
                  <input
                      name="searchKey"
                      type="text"
                      className="input"
                      placeholder="Search Jobs"
                      value={searchKeyTerms}
                      onChange={(e) => setSearchKeyTerms(e.target.value)}
                    />              
              </div>
            </div>
            <div className="column">
              <div className="tabs is-bigger is-right">
                {/* <ul>
                  <li
                    className={activeTab === 1 ? "is-active" : ""}
                    onClick={() => setActiveTab(1)}
                  >
                    <a href="/#" onClick={ignoreDefaultEvent}>
                      Current Jobs
                    </a>
                  </li>
                  <li
                    className={activeTab === 2 ? "is-active" : ""}
                    onClick={() => setActiveTab(2)}
                  >
                    <a href="/#" onClick={ignoreDefaultEvent}>
                      Expired/Closed
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body body-spacer">{<CurrentJobListingsPage
            jobs={currentJobs}
            showDetailHandler={showDetails}
          />}</div>
      </div>
    </div>
  );
};

export default StudentJobListingsPage;
