import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SavedJobSliceModel } from "./saved-job.slice.model";
import { AppInitialState } from "../../app.initial.state";

const initialState: SavedJobSliceModel = AppInitialState.savedJobModel;

export const SavedJobSlice = createSlice({
  name: "savedJobReducer",
  initialState,
  reducers: {
    fetchSavedJobs: (state) => {
      state.isFetchingSavedJobs = true;
    },
    fetchSavedJobsSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
      state.isFetchingSavedJobs = false;
      state.savedJobs = action.payload;
    },
    fetchSavedJobsFail: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isFetchingSavedJobs = false;
    },
  },
});

export const { fetchSavedJobs, fetchSavedJobsSuccess, fetchSavedJobsFail } =
  SavedJobSlice.actions;

export default SavedJobSlice.reducer;
