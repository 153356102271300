import axios from "axios";
//`${process.env.REACT_APP_WEBAPP_BASE_URL}/check-login`,
export const baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
// export const baseURL =
//   "https://f8mjnp9932.execute-api.ap-southeast-2.amazonaws.com/";
// export const baseURL =
//    "https://tzjj2mevfl.execute-api.ap-southeast-2.amazonaws.com/";

export default axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`
  //baseURL: "https://f8mjnp9932.execute-api.ap-southeast-2.amazonaws.com/",
  //baseURL: "https://tzjj2mevfl.execute-api.ap-southeast-2.amazonaws.com/",
});
