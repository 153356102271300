import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobSliceModel } from "./job.slice.model";
import { AppInitialState } from "../../app.initial.state";

const initialState: JobSliceModel = AppInitialState.jobModel;

export const JobSlice = createSlice({
  name: "jobReducer",
  initialState,
  reducers: {
    fetchJobs: (state) => {
      state.isFetchingJobs = true;
    },
    fetchJobsSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
      state.isFetchingJobs = false;
      state.jobs = action.payload;
    },
    fetchJobsFail: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isFetchingJobs = false;
    },
  },
});

export const { fetchJobs, fetchJobsSuccess, fetchJobsFail } = JobSlice.actions;

export default JobSlice.reducer;
