import React, { useEffect, useState } from "react";
import ApplicationAPIService from "../../../services/application/application.service";

import { show, hide } from "../../../store/slices/loading/loading.slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchApplications,
  fetchApplicationsSuccess,
  fetchApplicationsFail,
} from "../../../store/slices/application/application.slice";
import ReactPaginate from "react-paginate";
import * as FaIcons from "react-icons/fa";

const ApplicationListComponent = (props: any) => {
  const dispatch = useAppDispatch();
  const isFetchingApplications = useAppSelector(
    (state) => state.applicationReducer.isFetchingApplications
  );

  const allApplications = useAppSelector(
    (state) => state.applicationReducer.applications
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [searchKeyTerms, setSearchKeyTerms] = useState("");

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const allFilteredApplications = allApplications.filter((application: any) => {
    let found = false;
    if (searchKeyTerms === "") {
      found = true;
    } else if (
      application.CompanyNameToSend.toLowerCase().includes(
        searchKeyTerms.toLocaleLowerCase()
      ) ||
      application.Email.toLowerCase().includes(
        searchKeyTerms.toLocaleLowerCase()
      )
    ) {
      found = true;
    }
    return found;
  });

  const pageCount = Math.ceil(allFilteredApplications.length / itemsPerPage);

  const displayItems = allFilteredApplications
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.CompanyNameToSend}</td>
          <td>{item.Email}</td>
          <td>
            {item.FilterData === "APPLICATIONCODE#PENDING" && (
              <div>pending</div>
            )}

            {item.FilterData === "APPLICATIONCODE#SUBMITTED" && (
              <div>submitted</div>
            )}
          </td>
        </tr>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    if (!isFetchingApplications) {
      dispatch(fetchApplications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingApplications) {
      dispatch(show());
      ApplicationAPIService.getAllApplications()
        .then((application) => {
          console.log(application);
          dispatch(fetchApplicationsSuccess(application.data));
          dispatch(hide());
        })
        .catch((error) => {
          dispatch(fetchApplicationsFail(error));
          dispatch(hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingApplications]);

  return (
    <>
      <div className="application-list">
        <div className="page-sub-heading bottom-top-spacing">
          Sent Invitation History
        </div>
        <div className="category-list">
          <div className="search-bar">
            <input
              name="searchKey"
              type="text"
              className="input"
              placeholder="Search Name or Email"
              value={searchKeyTerms}
              onChange={(e) => setSearchKeyTerms(e.target.value)}
            />
          </div>
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Contact Name / Company Name</th>
                <th>Email</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>{displayItems}</tbody>
          </table>
        </div>
        <nav className="pagination" role="navigation" aria-label="pagination">
          <ReactPaginate
            previousLabel={
              <FaIcons.FaChevronLeft className="pagination-previous" />
            }
            nextLabel={<FaIcons.FaChevronRight className="pagination-next" />}
            pageCount={pageCount}
            onPageChange={changePage}
            pageClassName={"pagination-link"}
            containerClassName={"pagination-list"}
            previousLinkClassName={"previous-btns"}
            nextLinkClassName={"next-btns"}
            disabledClassName={"pagination-disabled"}
            activeClassName={"is-current"}
          />
        </nav>
      </div>
    </>
  );
};

export default ApplicationListComponent;
