import { createContext, ReactNode, useState, useContext, useRef } from "react";

import { JobSeekerAuthService } from "../../../services/auth/jobseeker-auth-service";

export interface IUser {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  position: string;
}

export interface IAuth {
  user: IUser;
  roles: string[];
}

export interface IjCount {
  data?: string;
}

export type AuthContextProps = {
  authService: JobSeekerAuthService;
  auth: IAuth | null;
  updateAuth: (newUser: IUser, assignedRole: string) => void;
  jcount: React.MutableRefObject<string>;
  updateCount: () => void;
};

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
  authService: JobSeekerAuthService;
}

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const JobSeekerAuthProvider = (props: AuthProviderProps) => {
  const { children, authService } = props;
  const [auth, setAuth] = useState<IAuth | null>(null);
  const jcount = useRef<string>("Test") as React.MutableRefObject<string>;

  const updateAuth = (newUser: IUser, assignedRole: string) => {
    const roleArray = [assignedRole];
    setAuth({ user: newUser, roles: roleArray });
  };

  const updateCount = () => {
    jcount.current = "Lelele";
  };

  return (
    <AuthContext.Provider
      value={{ auth, authService, updateAuth, jcount, updateCount }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
