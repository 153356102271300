import React, { useState } from "react";
import { RouteProps } from "react-router";

import "./login.page.scss";

import ElipseBackground from "../../assets/elipse_background.png";
import BackgroundContainer from "../../assets/login-background.png";
import UoALogo from "../../assets/uoa-logo.png";
import ContactForm from "../../common/components/contact-form/contact-form.component";

import { useAuth } from "../../store/context/auth/auth.provider";

export type LoginPageProps = {} & RouteProps;

const LoginPage = (props: LoginPageProps) => {
  const { authService } = useAuth();
  const [showDialog, setShowDialog] = useState(false);

  const login = async () => {
    authService.authorize();
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <div
        className="background-countainer"
        style={{ backgroundImage: `url(${BackgroundContainer})` }}
      >
        {showDialog && <ContactForm closeDialogHandler={closeDialog} />}

        <div className="login-outer-container">
          <div
            className="login-inner-container"
            style={{ backgroundImage: `url(${ElipseBackground})` }}
          >
            <div className="header-title-container">
              <img src={UoALogo} className="login-logo" alt="Logo" />
              <div className="login-title">CS Job Seeker</div>
              <div className="login-sub-title">student job board system</div>
            </div>
            <div className="login-form">
              <div className="student-login">
                <div className="form-label">Student Login / Admin</div>
                <button className="button is-fullwidth-80" onClick={login}>
                  University of Auckland Single Sign-on
                </button>
              </div>
              <div className="provider-login">
                <div className="form-label">Employers</div>
                <button
                  className="button is-fullwidth-80"
                  onClick={() => setShowDialog(true)}
                >
                  Apply for a listing
                </button>
              </div>
              <div className="admin-login"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
