import { AppModel } from "./app.model";
import { StorageService as storage } from "../services/storage/storage.service";

export const AppInitialState: AppModel = {
  loadingModel: {
    show: false,
  },
  loginModel: {
    token: storage.getItem("jBToken"),
    isStayLoggedIn: storage.getItem("jBSLI") ? true : false,
    error: null,
    isLoggedIn: false,
    isLoggingIn: false,
    isChecking: false,
    isRecoveredPassword: false,
    isRecoveringPassword: false,
    profile: null,
  },
  authModel: {
    auth: null,
  },
  jobModel: {
    error: null,
    isFetchingJobs: false,
    jobs: [],
  },
  applicationModel: {
    error: null,
    isFetchingApplications: false,
    applications: [],
  },
  savedJobModel: {
    error: null,
    isFetchingSavedJobs: false,
    savedJobs: [],
  },
  categoryModel: {
    error: null,
    isFetchingCategories: false,
    categories: [],
  },
  userModel: {
    error: null,
    isFetchingUsers: false,
    users: [],
  },
};
