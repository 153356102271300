import React, { useState } from "react";
import BreadCrumbsComponent from "../../common/components/breadcrumbs/breadcrumbs.component";
import { show, hide } from "../../store/slices/loading/loading.slice";
import { useAppDispatch } from "../../store/hooks";
import JobService from "../../services/job/job.service";
import MessageDialog from "../../common/components/message-dialog/message-dialog.component";
import ApplicationListComponent from "../../common/components/application/application-lists";
import { fetchApplications } from "../../store/slices/application/application.slice";

const InviteToApplyPage = () => {
  const dispatch = useAppDispatch();
  const [emailToInvite, setEmailToInvite] = useState("");
  const [companyNameToSend, setCompanyNameToSend] = useState("");
  const applicationURL = `${process.env.REACT_APP_WEBAPP_BASE_URL}`;
  const [showDialog, setShowDialog] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData: string = window.localStorage.getItem("userData") || "";
    const userDataItem = JSON.parse(userData);

    console.log(emailToInvite);
    dispatch(show());
    JobService.inviteToApply(
      emailToInvite,
      applicationURL,
      companyNameToSend,
      userDataItem.userName
    )
      .then((result) => {
        console.log(result);
        dispatch(hide());
        dispatch(fetchApplications());
        setShowDialog(true);
      })
      .catch((error) => {
        dispatch(hide());
      });
  };

  const closeDialog = () => {
    setEmailToInvite("");
    setShowDialog(false);
  };

  return (
    <div className="container custom-layout">
      {showDialog && (
        <MessageDialog
          closeDialogHandler={closeDialog}
          message="Invitation Sent!"
        />
      )}

      <div className="subheader-layout">
        <BreadCrumbsComponent mainCrumb="Settings" subCrumb="Invite to Apply" />
      </div>
      <div className="card">
        <div className="card-header header-spacer no-border-section">
          <h1 className="page-sub-heading">Send Invite Link</h1>
        </div>
        <div className="card-body body-spacer">
          <form onSubmit={handleSubmit}>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input
                      name="companyName"
                      type="text"
                      className="input"
                      placeholder="Contact Name/Company Name"
                      value={companyNameToSend}
                      onChange={(e) => setCompanyNameToSend(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input
                      name="emailToInvite"
                      type="text"
                      className="input"
                      placeholder="Enter Email"
                      value={emailToInvite}
                      onChange={(e) => setEmailToInvite(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button className="button is-primary">Submit</button>
          </form>
          <ApplicationListComponent />
        </div>
      </div>
    </div>
  );
};

export default InviteToApplyPage;
