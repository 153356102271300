import React from "react";
import JobService from "../../services/job/job.service";

import {
  fetchJobsSuccess,
  fetchJobsFail,
} from "../../store/slices/job/job.slice";
import { show, hide } from "../../store/slices/loading/loading.slice";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

const CurrentJobListingsPage = (props: any) => {
  const dispatch = useAppDispatch();

  let userID: string =
    useAppSelector((state) => state.authReducer.auth?.user?.userID) || "";

  const saveJob = (userID: string, jobID: string, jobRole: string) => {
    dispatch(show());
    JobService.saveJobToMyJobs(userID, jobID, jobRole).then((res: any) => {
      if (res.status === 200) {
        JobService.getCurrentJobsByUser(userID)
          .then((jobs) => {
            dispatch(fetchJobsSuccess(jobs.data));
            dispatch(hide());
          })
          .catch((error) => {
            dispatch(fetchJobsFail(error));
            dispatch(hide());
          });
      } else {
        dispatch(hide());
      }
    });
  };

  const showDetails = (job: string) => {
    props.showDetailHandler(job);
  };

  return (
    <>
      <div className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>
                <abbr title="Position">Position</abbr>
              </th>
              <th>
                <abbr title="Company">Company</abbr>
              </th>
              <th>
                <abbr title="Employment Type">Employment Type</abbr>
              </th>
              <th>
                <abbr title="Closing Date">Closing</abbr>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {props.jobs.map((job, index) => {
              console.log(job);
              return (
                <tr key={index}>
                  <td>
                    <div
                      onClick={() => showDetails(job)}
                      className="detail-hand-cursor"
                    >
                      {job.JobRole}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => showDetails(job)}
                      className="detail-hand-cursor"
                    >
                      {job.Company}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => showDetails(job)}
                      className="detail-hand-cursor"
                    >
                      {job.JobWorkType}
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => showDetails(job)}
                      className="detail-hand-cursor"
                    >
                      {job.JobApplicationEndDate}
                    </div>
                  </td>
                  <td>
                    {!job.JobSaved && (
                      <button
                        className="button is-success is-small button-custom-small"
                        onClick={() => saveJob(userID, job.JobID, job.JobRole)}
                      >
                        Save
                      </button>
                    )}
                    {job.JobSaved && (
                      <div className="button cursor-default is-saved is-small button-custom-small">
                        Saved
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CurrentJobListingsPage;
