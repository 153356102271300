import { Routes, Route } from "react-router-dom";

import JobListingsPage from "./pages/job-listings/job-listings.page";

import LoginPage from "./pages/login/login.page";
import LoginCheckPage from "./pages/login-check/login-check.page";

import AddCategoryPage from "./pages/add-category/add-category.page";
import AddJobListingPage from "./pages/job-listings/add-job-listing.page";

import StudentJobListingsPage from "./pages/student-job-listings/student-job-listings.page";
import SavedJobListingsPage from "./pages/saved-job-listings/saved-job-listings.page";
import UserListPage from "./pages/user-list/user-list.page";
import SubmitJobListingPage from "./pages/submit-job-listing/submit-job-listing.page";
import InviteToApplyPage from "./pages/invite-to-apply/invite-to-apply.page";

import LayoutHeaderComponent from "./common/components/layout/layout.header.component";
import LayoutNoHeaderComponent from "./common/components/layout/layout.noheader.component";
import LayoutNaDahComponent from "./common/components/layout/layout.nadah.component";
import LayoutCustomHeaderComponent from "./common/components/layout/layout.custom-header.component";

import SettingsAdminPage from "./pages/settings-admin/settings-admin.page";
import ProfilePage from "./pages/profile/profile.page";

import "./App.scss";

import RequireAuth from "./common/components/auth/require.auth.component";

function App() {
  return (
    <Routes>
      <Route path="/check-login" element={<LayoutNaDahComponent />}>
        <Route path="/check-login" element={<LoginCheckPage />} />
      </Route>
      <Route path="/login" element={<LayoutNoHeaderComponent />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>
      <Route
        path="/submit-job-listing"
        element={<LayoutCustomHeaderComponent />}
      >
        <Route
          path="/submit-job-listing/:id"
          element={<SubmitJobListingPage />}
        />
      </Route>
      <Route path="/" element={<LayoutHeaderComponent />}>
        <Route element={<RequireAuth allowedRoles={["student", "admin"]} />}>
          <Route path="/" element={<StudentJobListingsPage />} />
          <Route
            path="/student-job-listings/job-listings"
            element={<StudentJobListingsPage />}
          />
          <Route
            path="/student-job-listings/saved-job-listings"
            element={<SavedJobListingsPage />}
          />
        </Route>
        <Route element={<RequireAuth allowedRoles={["admin"]} />}>
          <Route path="job-management" element={<JobListingsPage />} />
          <Route
            path="job-management/add-job-listing"
            element={<AddJobListingPage />}
          />
          <Route
            path="job-management/job-listings"
            element={<JobListingsPage />}
          />
          <Route
            path="job-management/invite-to-apply"
            element={<InviteToApplyPage />}
          />
          <Route path="settings/add-category" element={<AddCategoryPage />} />
          <Route path="settings/user-list" element={<UserListPage />} />
          <Route
            path="settings/settings-admin"
            element={<SettingsAdminPage />}
          />
          <Route path="settings/profile" element={<ProfilePage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
