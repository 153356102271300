import { useRef, useState } from "react";
import Reaptcha from "reaptcha";
import { useForm } from "react-hook-form";
import { show, hide } from "../../../store/slices/loading/loading.slice";
import { useAppDispatch } from "../../../store/hooks";
import JobService from "../../../services/job/job.service";

const ContactForm = ({ closeDialogHandler }) => {
  // const [captchaToken, setCaptchaToken] = useState(null);

  const dispatch = useAppDispatch();

  const [verified, setVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [recaptchaRequired, setRecaptchaRequired] = useState(false);
  const captchaRef = useRef<any>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const verify = () => {
    if (captchaRef.current) {
      captchaRef.current.getResponse().then((res) => {
        // setCaptchaToken(res);
        setVerified(true);
      });
    }
  };

  const closeDialog = () => {
    closeDialogHandler();
  };

  const onSubmit = (data: any) => {
    if (verified) {
      setSubmitted(true);
      dispatch(show());
      JobService.sendRequestToJobList({
        FullName: data.FullName,
        Email: data.Email,
        EmailSubject: "UoA CS Job Seeker - Request Job Listing Form",
        CompanyDetails: data.CompanyDetails,
      })
        .then((res) => {
          dispatch(hide());
        })
        .catch((error) => {
          dispatch(hide());
        });
    } else {
      setRecaptchaRequired(true);
      console.log("Not Verified");
    }
  };

  const siteKeyValue = `${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}`;

  const mainContent = () => (
    <div className="custom-active-modal contact-form">
      <div className="custom-modal-background"></div>
      <div className="modal-content no-scroll">
        <div className="modal-content-border">
          <div className="modal-content-wrapper">
            <div className="message-content" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="intro-message">
                      Thank you for your interest. Please complete and submit
                      the form below.
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        {...register("FullName", { required: true })}
                        name="FullName"
                        type="text"
                        className="input"
                        placeholder="Full Name"
                      />
                      <div className="error-message">
                        {errors.FullName && <span>fullname is required</span>}
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        {...register("Email", { required: true })}
                        name="Email"
                        type="text"
                        className="input"
                        placeholder="Email"
                      />
                      <div className="error-message">
                        {errors.email && <span>valid email is required</span>}
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <textarea
                        {...register("CompanyDetails", { required: true })}
                        name="CompanyDetails"
                        className="textarea"
                        placeholder="Position/Company Details/What sort of job you would like to post."
                      ></textarea>
                      <div className="error-message">
                        {errors.CompanyDetails && (
                          <span>company details is required</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <Reaptcha
                        sitekey={siteKeyValue}
                        ref={captchaRef}
                        onVerify={verify}
                      />
                      <div className="error-message">
                        {recaptchaRequired && (
                          <span>ReCaptcha is required</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <button>Submit</button>
                </div>
              </div>
            </form>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={closeDialog}
          ></button>
        </div>
      </div>
    </div>
  );

  const submittedContent = () => (
    <div className="custom-active-modal contact-form">
      <div className="custom-modal-background"></div>
      <div className="modal-content no-scroll">
        <div className="modal-content-border">
          <div className="modal-content-wrapper">
            <div className="message-content">
              Thank you. We will send you a link to a job registeration form as
              soon as we verify your information.
            </div>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={closeDialog}
          ></button>
        </div>
      </div>
    </div>
  );

  return <>{submitted ? submittedContent() : mainContent()}</>;
};

export default ContactForm;
