import axios from "../helper/axios.helper";

class JobAPIService {
  configNoToken() {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      },
    };

    return config;
  }

  configPostNoToken() {
    const config = {
      headers: {},
    };

    return config;
  }

  configGet() {
    const token = JSON.parse(
      JSON.parse(JSON.stringify(window.localStorage.getItem("auth")))
    );

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `${token.access_token}`,
        Accept: "application/json",
      },
    };

    return config;
  }

  configPost() {
    const token = JSON.parse(
      JSON.parse(JSON.stringify(window.localStorage.getItem("auth")))
    );

    const configPost = {
      headers: {
        Authorization: `${token.access_token}`,
      },
    };

    return configPost;
  }

  getAllJobs() {
    return axios.get("/jobs", this.configGet());
  }

  getCurrentJobsByUser(userID: string) {
    return axios.get(`/current-jobs-byuser/${userID}`);
  }

  getSavedJobsByUser(userID: string) {
    return axios.get(`/saved-job/${userID}`);
  }

  saveJobToMyJobs(userID: string, jobID: string, jobRole: string) {
    return axios.post(
      "/saved-job",
      {
        UserID: userID,
        JobID: jobID,
        JobRole: jobRole,
      },
      this.configPost()
    );
  }

  deleteJobFromMyJobs(userID: string, jobID: string, savedJobID: string) {
    return axios.post(
      "/delete-saved-job",
      {
        UserID: userID,
        JobID: jobID,
        SavedJobID: savedJobID,
      },
      this.configPost()
    );
  }

  updateJobs(pk: string, sk: string, status: string) {
    return axios.post(
      "/change-job-status",
      {
        PK: pk,
        SK: sk,
        NewStatus: status,
      },
      this.configPost()
    );
  }

  createJob(jobData: any) {
    console.log(jobData);
    return axios.post(
      "/job",
      {
        CategoryID: jobData.CategoryID,
        JobCategory: jobData.JobCategory,
        JobRole: jobData.JobRole,
        JobDescription: jobData.JobDescription,
        JobKeySkills: jobData.JobKeySkills,
        JobMinimumQualification: jobData.JobMinimumQualification,
        JobWorkType: jobData.JobWorkType,
        JobPayType: jobData.JobPayType,
        JobLocation: jobData.JobLocation,
        JobWorkplace: jobData.JobWorkplace,
        MaxHoursPartime: jobData.MaxHoursPartime,
        JobApplicationStartDate: jobData.JobApplicationStartDate,
        JobApplicationEndDate: jobData.JobApplicationEndDate,
        Referral: jobData.Referral,
        Company: jobData.Company,
        CompanyDescription: jobData.CompanyDescription,
        CompanyContactName: jobData.CompanyContactName,
        CompanyContactEmail: jobData.CompanyContactEmail,
        CompanyContactNumber: jobData.CompanyContactNumber,
        CompanyWebsite: jobData.CompanyWebsite,
        ApplyLink: jobData.ApplyLink,
        ApplyLinkURL: jobData.ApplyLinkURL,
        ShowContactInformation: jobData.ShowContactInformation,
        Status: "Accepted",
      },
      this.configPost()
    );
  }

  submitJob(jobData: any) {
    return axios.post(
      "/submit-job",
      {
        CategoryID: jobData.CategoryID,
        JobCategory: jobData.JobCategory,
        JobRole: jobData.JobRole,
        JobDescription: jobData.JobDescription,
        JobKeySkills: jobData.JobKeySkills,
        JobMinimumQualification: jobData.JobMinimumQualification,
        JobWorkType: jobData.JobWorkType,
        JobPayType: jobData.JobPayType,
        JobLocation: jobData.JobLocation,
        JobApplicationStartDate: jobData.JobApplicationStartDate,
        JobApplicationEndDate: jobData.JobApplicationEndDate,
        Company: jobData.Company,
        CompanyDescription: jobData.CompanyDescription,
        CompanyContactName: jobData.CompanyContactName,
        CompanyContactEmail: jobData.CompanyContactEmail,
        CompanyContactNumber: jobData.CompanyContactNumber,
        CompanyWebsite: jobData.CompanyWebsite,
        ApplicationID: jobData.ApplicationID,
        ApplyLink: jobData.ApplyLink,
        ApplyLinkURL: jobData.ApplyLinkURL,
        ShowContactInformation: jobData.ShowContactInformation,
        Status: "Pending",
      },
      this.configPostNoToken()
    );
  }

  inviteToApply(
    sendTo: string,
    applicationURL: string,
    companyNameToSend: string,
    userName: string
  ) {
    return axios.post(
      "/send-mail",
      {
        UserName: userName,
        SendTo: sendTo,
        SendFrom: "d.dimalen@auckland.ac.nz",
        EmailSubject: "UoA CS Job Seeker - Job Listing",
        MessageText: "",
        EmailCode: 1,
        ApplicationURL: applicationURL,
        CompanyNameToSend: companyNameToSend,
      },
      this.configPost()
    );
  }

  sendRequestToJobList(requestData: any) {
    return axios.post(
      "/send-request-joblisting",
      {
        FullName: requestData.FullName,
        Email: requestData.Email,
        EmailSubject: requestData.EmailSubject,
        CompanyDetails: requestData.CompanyDetails,
      },
      this.configPostNoToken()
    );
  }

  checkApplicationID(applicationID: string) {
    return axios.post("/get-application-byid", {
      ApplicationID: applicationID,
    });
  }
}

export default new JobAPIService();
