import React from "react";
import { ReactComponent as LoadingWheel } from "../../../assets/loading.svg";
import { useAppSelector } from "../../../store/hooks";

const LoadingComponent = () => {
  const showLoading = useAppSelector((state) => state.loadingReducer.show);

  return showLoading ? (
    <div className="loading-icon">
      <LoadingWheel />
    </div>
  ) : null;
};

export default LoadingComponent;
