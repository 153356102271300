import { useEffect, useState } from "react";
import JobService from "../../services/job/job.service";
import BreadCrumbsComponent from "../../common/components/breadcrumbs/breadcrumbs.component";

import {
  fetchSavedJobs,
  fetchSavedJobsSuccess,
  fetchSavedJobsFail,
} from "../../store/slices/saved-job/saved-job.slice";
import { show, hide } from "../../store/slices/loading/loading.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import JobDetailsComponent from "../../common/components/job-details/job-details.component";

import "./saved-job-listings.style.scss";

const SavedJobListingsPage = () => {
  const [showDialog, setShowDialog] = useState(false);
  const dispatch = useAppDispatch();
  const [jobDetailContent, setJobDetailContent] = useState({});
  const [searchKeyTerms, setSearchKeyTerms] = useState("");

  const isFetchingSavedJobs = useAppSelector(
    (state) => state.savedJobReducer.isFetchingSavedJobs
  );

  const allSavedJobs = useAppSelector(
    (state) => state.savedJobReducer.savedJobs
  );

  let userID: string =
    useAppSelector((state) => state.authReducer.auth?.user?.userID) || "";

  const pickedSavedJobs = allSavedJobs.filter((job: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const closingDate = new Date(job.JobApplicationEndDate);
    let found = false;
    if (searchKeyTerms === "") {
      found = true;
    } else if (
      job.JobRole.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase())
    ) {
      found = true;
    }
    return job.Status === "Accepted" && closingDate >= today && found;
  });

  useEffect(() => {
    if (!isFetchingSavedJobs) {
      dispatch(fetchSavedJobs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingSavedJobs) {
      dispatch(show());
      JobService.getSavedJobsByUser(userID)
        .then((jobs) => {
          dispatch(fetchSavedJobsSuccess(jobs.data));
          dispatch(hide());
        })
        .catch((error) => {
          dispatch(fetchSavedJobsFail(error));
          dispatch(hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingSavedJobs]);

  const showDetails = (job: any) => {
    setShowDialog(true);
    setJobDetailContent(job);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const deleteJob = (userID: string, jobID: string, savedJobID: string) => {
    dispatch(show());
    JobService.deleteJobFromMyJobs(userID, jobID, savedJobID).then(
      (res: any) => {
        if (res.status === 200) {
          JobService.getSavedJobsByUser(userID)
            .then((jobs) => {
              dispatch(fetchSavedJobsSuccess(jobs.data));
              dispatch(hide());
            })
            .catch((error) => {
              dispatch(fetchSavedJobsFail(error));
              dispatch(hide());
            });
        } else {
          dispatch(hide());
        }
      }
    );
  };

  const currentPageTitlle = "Saved Job Listings";

  return (
    <div className="container custom-layout">
      {showDialog && (
        <JobDetailsComponent
          closeDialogHandler={closeDialog}
          jobDetail={jobDetailContent}
        />
      )}

      <div className="subheader-layout">
        <BreadCrumbsComponent
          mainCrumb="Job Listings"
          subCrumb={currentPageTitlle}
        />
      </div>
      <div className="card">
        <div className="card-header header-spacer no-border-section">
          <h1>{currentPageTitlle}</h1>
        </div>
        <div className="card-header header-spacer">
          <div className="columns">
            <div className="column is-half">
              <div className="search-bar">
                <input
                  name="searchKey"
                  type="text"
                  className="input"
                  placeholder="Search Jobs"
                  value={searchKeyTerms}
                  onChange={(e) => setSearchKeyTerms(e.target.value)}
                />
              </div>
            </div>
            <div className="column"></div>
          </div>
        </div>
        <div className="card-body body-spacer">
          <div className="table-container">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>
                    <abbr title="Position">Position</abbr>
                  </th>
                  <th>
                    <abbr title="Company">Company</abbr>
                  </th>
                  <th>
                    <abbr title="Employment Type">Employment Type</abbr>
                  </th>
                  <th>
                    <abbr title="Closing Date">Closing</abbr>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pickedSavedJobs.map((job, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div
                          onClick={() => showDetails(job)}
                          className="detail-hand-cursor"
                        >
                          {job.JobRole}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div
                          onClick={() => showDetails(job)}
                          className="detail-hand-cursor"
                        >
                          {job.Company}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div
                          onClick={() => showDetails(job)}
                          className="detail-hand-cursor"
                        >
                          {job.JobWorkType}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div
                          onClick={() => showDetails(job)}
                          className="detail-hand-cursor"
                        >
                          {job.JobApplicationEndDate}
                        </div>
                      </td>
                      <td>
                        <button
                          className="button is-danger is-small"
                          onClick={() =>
                            deleteJob(userID, job.JobID, job.SavedJobID)
                          }
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedJobListingsPage;
