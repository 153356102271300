import { useState } from "react";

import JobService from "../../services/job/job.service";

import {
  fetchJobsSuccess,
  fetchJobsFail,
} from "../../store/slices/job/job.slice";
import { show, hide } from "../../store/slices/loading/loading.slice";

import { useAppDispatch } from "../../store/hooks";

import ReactPaginate from "react-paginate";
import * as FaIcons from "react-icons/fa";

const JobListingsPending = (props: any) => {
  const dispatch = useAppDispatch();

  const updateJobStatus = (pk: string, sk: string, newStatus: string) => {
    const pkArray = pk.split("#");
    const skArray = sk.split("#");
    dispatch(show());
    JobService.updateJobs(pkArray[1], skArray[1], newStatus)
      .then((res: any) => {
        if (res.status === 200) {
          JobService.getAllJobs()
            .then((jobs) => {
              dispatch(fetchJobsSuccess(jobs.data));
              dispatch(hide());
            })
            .catch((error) => {
              dispatch(fetchJobsFail(error));
              dispatch(hide());
            });
        } else {
          dispatch(hide());
        }
      })
      .catch((error) => {
        dispatch(hide());
      });
  };

  const showDetails = (job: string) => {
    props.showDetailHandler(job);
  };

  const [pageNumber, setPageNumber] = useState(0);

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;

  const pageCount = Math.ceil(props.jobs.length / itemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayItems = props.jobs
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((job, index) => {
      return (
        <tr key={index}>
          <td onClick={() => showDetails(job)} className="detail-hand-cursor">
            {job.JobRole}
          </td>
          <td onClick={() => showDetails(job)} className="detail-hand-cursor">
            {job.Company}
          </td>
          <td onClick={() => showDetails(job)} className="detail-hand-cursor">
            {job.JobWorkType}
          </td>
          <td onClick={() => showDetails(job)} className="detail-hand-cursor">
            {job.JobApplicationEndDate}
          </td>
          <td>
            <button
              className="button is-success is-small"
              style={{ marginRight: "5px" }}
              onClick={() => updateJobStatus(job.PK, job.SK, "Accepted")}
            >
              Accept
            </button>
            <button
              className="button is-danger is-small"
              onClick={() => updateJobStatus(job.PK, job.SK, "Declined")}
            >
              Decline
            </button>
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>
                <abbr title="Position">Position</abbr>
              </th>
              <th>
                <abbr title="Company">Company</abbr>
              </th>
              <th>
                <abbr title="Employment Type">Employment Type</abbr>
              </th>
              <th>
                <abbr title="Closing Date">Closing</abbr>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{displayItems}</tbody>
        </table>
      </div>
      {pageCount > 0 && (
        <nav className="pagination" role="navigation" aria-label="pagination">
          <ReactPaginate
            previousLabel={
              <FaIcons.FaChevronLeft className="pagination-previous" />
            }
            nextLabel={<FaIcons.FaChevronRight className="pagination-next" />}
            pageCount={pageCount}
            onPageChange={changePage}
            pageClassName={"pagination-link"}
            containerClassName={"pagination-list"}
            previousLinkClassName={"previous-btns"}
            nextLinkClassName={"next-btns"}
            disabledClassName={"pagination-disabled"}
            activeClassName={"is-current"}
          />
        </nav>
      )}
    </>
  );
};

export default JobListingsPending;
