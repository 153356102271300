import { useEffect } from "react";
import { RouteProps } from "react-router";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LoadingWheel } from "../../assets/loading.svg";

import { useAuth } from "../../store/context/auth/auth.provider";
import { JWTIDToken } from "../../services/auth/jobseeker-auth-service";
import jwtDecode from "jwt-decode";
import axios from "../../services/helper/axios.helper";

import { useAppDispatch } from "../../store/hooks";
import { setAuth } from "../../store/slices/auth/auth.slice";

export type LoginCheckPageProps = {} & RouteProps;

const LoginCheckPage = (props: LoginCheckPageProps) => {
  const dispatch = useAppDispatch();
  const { authService } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const code = authService.getCodeFromLocation(window.location);

    if (code !== null) {
      authService
        .fetchToken(code)
        .then((t) => {
          const decoded = jwtDecode(t.id_token) as JWTIDToken;
          const userName = decoded.preferred_username;
          const email = decoded.email;
          const lastName = decoded.family_name;
          const firstName = decoded.given_name;
          const roleId = `${process.env.REACT_APP_STUDENT_ROLEID}`;
          const position = "Student";

          // console.log(t);
          // console.log(decoded);
          // console.log(roleId);

          axios
            .post("check-user", {
              RoleID: `${roleId}`,
              UserName: `${userName}`,
              FirstName: `${firstName}`,
              LastName: `${lastName}`,
              Position: `${position}`,
              Email: `${email}`,
            })
            .then((response) => {
              dispatch(
                setAuth({
                  user: {
                    userName: response.data.Items[0].UserName,
                    firstName: response.data.Items[0].FirstName,
                    lastName: response.data.Items[0].LastName,
                    email: response.data.Items[0].Email,
                    position: response.data.Items[0].Position,
                    userID: response.data.Items[0].UserID,
                  },
                  roles: [response.data.Items[0].Role],
                })
              );

              const userData = {
                userName: response.data.Items[0].UserName,
                firstName: response.data.Items[0].FirstName,
                lastName: response.data.Items[0].LastName,
                email: response.data.Items[0].Email,
                position: response.data.Items[0].Position,
                role: response.data.Items[0].Role,
              };

              window.localStorage.removeItem("userData");
              window.localStorage.setItem("userData", JSON.stringify(userData));
              authService.removeCodeFromLocation();
              if (userData.role === "admin") {
                navigate("/job-management/job-listings", { replace: true });
              } else {
                navigate("/student-job-listings/job-listings", {
                  replace: true,
                });
              }
            });
        })
        .catch((e) => {
          // authService.removeItem("pkce");
          // authService.removeItem("auth");
          // authService.removeCodeFromLocation();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingWheel />
    </>
  );
};

export default LoginCheckPage;
