import React from "react";
import "./breadcrumbs.component.style.scss";

interface BreadCrumbsComponentProps {
  mainCrumb: string;
  subCrumb?: string;
}

const BreadCrumbsComponent = ({
  mainCrumb,
  subCrumb,
}: BreadCrumbsComponentProps) => {
  return (
    <>
      <nav className="breadcrumb">
        <ul>
          <li className="maincrumb">
            <span>{mainCrumb}</span>
          </li>
          {subCrumb ? (
            <li className="subcrumb">
              <span>{subCrumb}</span>
            </li>
          ) : (
            ""
          )}
        </ul>
      </nav>
    </>
  );
};

export default BreadCrumbsComponent;
