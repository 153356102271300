import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationSliceModel } from "./application.slice.model";
import { AppInitialState } from "../../app.initial.state";

const initialState: ApplicationSliceModel = AppInitialState.applicationModel;

export const ApplicationSlice = createSlice({
  name: "applicationReducer",
  initialState,
  reducers: {
    fetchApplications: (state) => {
      state.isFetchingApplications = true;
    },
    fetchApplicationsSuccess: (state, action: PayloadAction<any>) => {
      let payloadContainer: any = [];
      if (action.payload) {
        payloadContainer = []
          .concat(action.payload)
          .sort((a: any, b: any) => (a.DateAdded < b.DateAdded ? 1 : -1))
          .map((item, i) => item);
      }

      state.error = null;
      state.isFetchingApplications = false;
      state.applications = payloadContainer;
    },
    fetchApplicationsFail: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isFetchingApplications = false;
    },
  },
});

export const {
  fetchApplications,
  fetchApplicationsSuccess,
  fetchApplicationsFail,
} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
