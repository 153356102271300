import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSliceModel } from "./user.slice.model";
import { AppInitialState } from "../../app.initial.state";

const initialState: UserSliceModel = AppInitialState.userModel;

export const UserSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    fetchUsers: (state) => {
      state.isFetchingUsers = true;
    },
    fetchUsersSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
      state.isFetchingUsers = false;
      state.users = action.payload;
    },
    fetchUsersFail: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.isFetchingUsers = false;
    },
  },
});

export const { fetchUsers, fetchUsersSuccess, fetchUsersFail } =
  UserSlice.actions;

export default UserSlice.reducer;
