import React from "react";
import * as FaIcons from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { NavLink } from "react-router-dom";

const NavigationStudentComponent = () => {
  const navigate = useNavigate();

  const logout = () => {
    window.localStorage.removeItem("pkce");
    window.localStorage.removeItem("auth");
    navigate("/login", { replace: true });
  };

  const reRouteLink = (e: any, link: string) => {
    e.preventDefault();
    navigate(link, { replace: true });
  };

  return (
    <div className="app-sidebar-menu">
      <ul>
        <li>
          <NavLink
            to="student-job-listings"
            className="mainmenu-heading"
            onClick={(e) =>
              reRouteLink(e, "/student-job-listings/job-listings")
            }
          >
            <FaIcons.FaBriefcase className="menu-icon" />
            <span>Job Listings</span>
          </NavLink>
          <div className="submmenu-options">
            <NavLink
              to="student-job-listings/job-listings"
              className="mainmenu-option"
            >
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>Current Job Listings</span>
            </NavLink>
            <NavLink
              to="student-job-listings/saved-job-listings"
              className="mainmenu-option"
            >
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>Saved Job Listings</span>
            </NavLink>
          </div>
        </li>
        {/* 
        <li>
          <div className="mainmenu-heading">
            <FaIcons.FaBell className="menu-icon" /> <span>Notifications</span>
          </div>
        </li> */}
        {/* <li>
          <NavLink
            to="student-job-listings"
            className="mainmenu-heading"
            onClick={(e) =>
              reRouteLink(e, "/student-job-listings/job-listings")
            }
          >
            <FaIcons.FaCog className="menu-icon" /> <span>Settings</span>
          </NavLink>
          <div className="submmenu-options">
            <Link to="#" className="mainmenu-option">
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>Account</span>
            </Link>
          </div>
        </li> */}
        <li>
          <div
            className="mainmenu-heading navigator-hand-cursor"
            onClick={logout}
          >
            <FaIcons.FaSignOutAlt className="menu-icon" /> <span>Logout</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default NavigationStudentComponent;
