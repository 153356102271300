import axios from "../helper/axios.helper";

class SettingsAPIService {
  configGet() {
    const token = JSON.parse(
      JSON.parse(JSON.stringify(window.localStorage.getItem("auth")))
    );

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `${token.access_token}`,
        Accept: "application/json",
      },
    };

    return config;
  }

  configNoToken() {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Accept: "application/json",
      },
    };

    return config;
  }

  configPost() {
    const token = JSON.parse(
      JSON.parse(JSON.stringify(window.localStorage.getItem("auth")))
    );

    const configPost = {
      headers: {
        Authorization: `${token.access_token}`,
      },
    };

    return configPost;
  }

  getAdminSettings() {
    return axios.post(
      "/settings-by-name",
      {
        SettingsName: "CurrentAdmin",
      },
      this.configPost()
    );
  }

  updateAdminSettings(adminData: any) {
    return axios.post(
      "/update-admin",
      {
        FirstName: adminData.FirstName,
        LastName: adminData.LastName,
        Position: adminData.Position,
        Department: adminData.Department,
        Address1: adminData.Address1,
        Address2: adminData.Address2,
        Phone: adminData.Phone,
        Email: adminData.Email,
      },
      this.configPost()
    );
  }
}

export default new SettingsAPIService();
