import { useState, useEffect } from "react";
import BreadCrumbsComponent from "../../common/components/breadcrumbs/breadcrumbs.component";
import { show, hide } from "../../store/slices/loading/loading.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import CategoryService from "../../services/category/category.service";
import {
  fetchCategories,
  fetchCategoriesSuccess,
  fetchCategoriesFail,
} from "../../store/slices/category/category.slice";

import ReactPaginate from "react-paginate";
import * as FaIcons from "react-icons/fa";

const AddCategoryPage = () => {
  const [category, setCategory] = useState("");
  const dispatch = useAppDispatch();
  const isFetchingCategories = useAppSelector(
    (state) => state.categoryReducer.isFetchingCategories
  );
  const allCategories = useAppSelector(
    (state) => state.categoryReducer.categories
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [searchKeyTerms, setSearchKeyTerms] = useState("");

  const itemsPerPage = 10;
  const pagesVisited = pageNumber * itemsPerPage;
  

  const allFilteredCategories = allCategories.filter((category: any) => {
    let found = false;    
    if(searchKeyTerms === "") {
      found = true;
    } else if(category.Name.toLowerCase().includes(searchKeyTerms.toLocaleLowerCase())) {
      found = true;
    }
    return found;
  })

  const pageCount = Math.ceil(allFilteredCategories.length / itemsPerPage);


  const displayItems = allFilteredCategories
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.Name}</td>
        </tr>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(show());
    CategoryService.createCategory(category)
      .then((result) => {
        CategoryService.getAllCategories()
          .then((categories) => {
            dispatch(fetchCategoriesSuccess(categories.data));
            dispatch(hide());
          })
          .catch((error) => {
            dispatch(fetchCategoriesFail(error));
            dispatch(hide());
          });
      })
      .catch((error) => {
        dispatch(hide());
      });
  };

  useEffect(() => {
    if (!isFetchingCategories) {
      dispatch(fetchCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingCategories) {
      dispatch(show());
      CategoryService.getAllCategories()
        .then((categories) => {
          dispatch(fetchCategoriesSuccess(categories.data));
          dispatch(hide());
        })
        .catch((error) => {
          dispatch(fetchCategoriesFail(error));
          dispatch(hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingCategories]);

  return (
    <div className="container custom-layout">
      <div className="subheader-layout">
        <BreadCrumbsComponent mainCrumb="Settings" subCrumb="Add Category" />
      </div>
      <div className="card">
        <div className="card-header header-spacer no-border-section">
          <h1>Add Category</h1>
        </div>
        <div className="card-body body-spacer">
          <form onSubmit={handleSubmit}>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <div className="control">
                    <input
                      name="categoryName"
                      type="text"
                      className="input"
                      placeholder="Category Name"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button className="button is-primary">Submit</button>
          </form>
          <hr />
          <div className="category-list">
          <div className="search-bar">
                  <input
                      name="searchKey"
                      type="text"
                      className="input"
                      placeholder="Search Categories"
                      value={searchKeyTerms}
                      onChange={(e) => setSearchKeyTerms(e.target.value)}
                    />              
              </div>
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Category</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>{displayItems}</tbody>
            </table>
          </div>
          <nav className="pagination" role="navigation" aria-label="pagination">
            <ReactPaginate
              previousLabel={
                <FaIcons.FaChevronLeft className="pagination-previous" />
              }
              nextLabel={<FaIcons.FaChevronRight className="pagination-next" />}
              pageCount={pageCount}
              onPageChange={changePage}
              pageClassName={"pagination-link"}
              containerClassName={"pagination-list"}
              previousLinkClassName={"previous-btns"}
              nextLinkClassName={"next-btns"}
              disabledClassName={"pagination-disabled"}
              activeClassName={"is-current"}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AddCategoryPage;
