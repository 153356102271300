import { Outlet } from "react-router-dom";
import LoadingComponent from "../loading/loading.component";

import "./layout.noheader.scss";

const LayoutNoHeaderComponent = () => {
  return (
    <>
      <LoadingComponent />
      <Outlet />
    </>
  );
};

export default LayoutNoHeaderComponent;
