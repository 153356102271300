import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import JobService from "../../services/job/job.service";
import { useParams } from "react-router-dom";

import CategoryService from "../../services/category/category.service";
import {
  fetchCategories,
  fetchCategoriesSuccess,
  fetchCategoriesFail,
} from "../../store/slices/category/category.slice";
import { show, hide } from "../../store/slices/loading/loading.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import MessageDialog from "../../common/components/message-dialog/message-dialog.component";

import "../../styles/datepicker.style.scss";

const SubmitJobListingPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const isFetchingCategories = useAppSelector(
    (state) => state.categoryReducer.isFetchingCategories
  );
  const allCategories = useAppSelector(
    (state) => state.categoryReducer.categories
  );
  const [selectedJobStartDate, setSelectedJobStartDate] = useState(new Date());
  const [selectedJobEndDate, setSelectedJobEndDate] = useState(new Date());
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(
    "This application has either expired or not found!"
  );
  const [success, setSuccess] = useState(false);
  const [applyLink, showApplyLink] = useState(false);
  const [showContactInformation, setShowContactInformation] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();

  const applicationID: any = params?.id;

  const onChangeApplyLink = () => {
    showApplyLink(!applyLink);
  };

  const onChangeShowContactInformation = () => {
    setShowContactInformation(!showContactInformation);
  };

  const submitHandler = (data: any) => {
    dispatch(show());

    const categoryArray = data.Category.split("#");
    const categoryID = categoryArray[0];
    const categoryName = categoryArray[1];

    JobService.submitJob({
      CategoryID: categoryID,
      JobCategory: categoryName,
      JobRole: data.JobRole,
      JobDescription: data.JobDescription,
      JobKeySkills: data.KeySkills,
      JobMinimumQualification: data.JobMinimumQualification,
      JobWorkType: data.JobWorkType,
      JobPayType: data.JobPayType,
      JobLocation: data.JobLocation,
      JobApplicationStartDate: moment(data.JobApplicationStartDate).format(
        "YYYY-MM-DD"
      ),
      JobApplicationEndDate: moment(data.JobApplicationEndDate).format(
        "YYYY-MM-DD"
      ),
      Company: data.CompanyName,
      CompanyDescription: data.CompanyDescription,
      CompanyContactName: data.ContactName,
      CompanyContactEmail: data.ContactEmail,
      CompanyContactNumber: data.ContactNumber,
      CompanyWebsite: data.Website,
      ApplyLink: data.ApplyLink,
      ApplyLinkURL: data.ApplyLinkURL,
      ShowContactInformation: data.ShowContactInformation,
      ApplicationID: applicationID,
    })
      .then((res) => {
        dispatch(hide());
        setShowDialog(true);
        setDialogMessage(
          "Job Listing has been submitted. You will receive an email notification once approved."
        );
        setSuccess(true);
      })
      .catch((error) => {
        dispatch(hide());
      });
  };

  const setJobStartHandler = (data: any) => {
    setSelectedJobStartDate(data);
    setValue("JobApplicationStartDate", data);
  };

  const setJobEndHandler = (data: any) => {
    setSelectedJobEndDate(data);
    setValue("JobApplicationEndDate", data);
  };

  const closeDialog = () => {
    setShowDialog(false);
    navigate("/", { replace: true });
  };

  useEffect(() => {
    dispatch(show());
    JobService.checkApplicationID(applicationID).then((result) => {
      if (Object.keys(result.data).length === 0) {
        setShowDialog(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFetchingCategories) {
      dispatch(fetchCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingCategories) {
      dispatch(show());
      CategoryService.getAllCategories()
        .then((categories) => {
          dispatch(fetchCategoriesSuccess(categories.data));
          dispatch(hide());
        })
        .catch((error) => {
          dispatch(fetchCategoriesFail(error));
          dispatch(hide());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingCategories]);

  return (
    <div className="container custom-layout">
      {showDialog && (
        <MessageDialog
          closeDialogHandler={closeDialog}
          message={dialogMessage}
          isSuccess={success}
        />
      )}

      <div className="card">
        <div className="card-header header-spacer no-border-section">
          <h1 className="job-listing-title">Submit Job Listing</h1>
        </div>
        <div className="card-body body-spacer">
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="form-section">
              <h2>Company / Organisation Information</h2>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Company / Organisation Name"
                        {...register("CompanyName", { required: true })}
                      />
                      {errors.CompanyName && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Company Description"
                        {...register("CompanyDescription", {
                          required: true,
                          minLength: 3,
                        })}
                      />
                      {errors.CompanyDescription && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
            </div>
            <div className="form-section">
              <h2>Contact Information</h2>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Contact Name"
                        {...register("ContactName", { required: true })}
                      />
                      {errors.ContactName && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Contact Email"
                        {...register("ContactEmail", { required: true })}
                      />
                      {errors.ContactEmail && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Contact Number"
                        {...register("ContactNumber", { required: true })}
                      />
                      {errors.ContactNumber && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Website"
                        {...register("Website", { required: true })}
                      />
                      {errors.Website && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
            </div>
            <div className="form-section">
              <h2>Job Information</h2>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Job / Role Title"
                        {...register("JobRole", { required: true })}
                      />
                      {errors.JobRole && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Job Description"
                        {...register("JobDescription", { required: true })}
                      />
                      {errors.JobDescription && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input"
                        placeholder="Key Skills (separated by comma e.i: javascript, angular, reactjs)"
                        {...register("KeySkills", { required: true })}
                      />
                      {errors.KeySkills && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Category</label>
                    <div className="control">
                      <div className="select">
                        <select {...register("Category", { required: true })}>
                          {allCategories.map((category, index) => {
                            return (
                              <option
                                value={`${category.CategoryID}#${category.Name}`}
                                key={index}
                              >
                                {category.Name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errors.Category && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">
                      Minimum Qualification Required
                    </label>
                    <div className="control">
                      <div className="select">
                        <select
                          {...register("JobMinimumQualification", {
                            required: true,
                          })}
                        >
                          <option value="Undergraduate">Undergraduate</option>
                          <option value="Postgraduate">Postgraduate</option>
                          <option value="PhD">PhD</option>
                          <option value="Alumni">Alumni</option>
                        </select>
                      </div>
                      {errors.JobMinimumQualification && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Employment Type</label>
                    <div className="control">
                      <div className="select">
                        <select
                          {...register("JobPayType", {
                            required: true,
                          })}
                        >
                          <option value="Internship">Internship</option>
                          <option value="Permanent">Permanent</option>
                          <option value="Casual">Casual</option>
                          <option value="Fixed Term">Fixed Term</option>
                        </select>
                      </div>
                      {errors.JobPayType && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Work hours</label>
                    <div className="control">
                      <div className="select">
                        <select
                          {...register("JobWorkType", {
                            required: true,
                          })}
                        >
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                        </select>
                      </div>
                      {errors.JobWorkType && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Location"
                        {...register("JobLocation", {
                          required: true,
                          minLength: 3,
                        })}
                      />
                      {errors.JobLocation && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Job Application Start Date</label>
                    <div className="control">
                      <DatePicker
                        className="input"
                        {...register("JobApplicationStartDate")}
                        selected={selectedJobStartDate}
                        onChange={(date) => setJobStartHandler(date)}
                        dateFormat="MMMM d, yyyy "
                        name="JobApplicationStartDate"
                      />
                      {errors.JobApplicationStartDate && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Job Application End Date</label>
                    <div className="control">
                      <DatePicker
                        className="input"
                        {...register("JobApplicationEndDate")}
                        selected={selectedJobEndDate}
                        onChange={(date) => setJobEndHandler(date)}
                        dateFormat="MMMM d, yyyy "
                        name="JobApplicationEndDate"
                      />
                      {errors.JobApplicationEndDate && (
                        <p className="help is-danger">required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* End of field row */}
            </div>
            <div className="form-section">
              <h2>To Apply</h2>
              {/* End of field row */}
              <div className="columns">
                <div className="column">
                  <div className="checkbox-separator">
                    <label className="checkbox">
                      <input
                        {...register("ApplyLink")}
                        type="checkbox"
                        name="ApplyLink"
                        checked={applyLink}
                        onChange={onChangeApplyLink}
                      />
                      Via the following link :{" "}
                      <div className="checkbox-subsection">
                        <input
                          className="input"
                          {...register("ApplyLinkURL", {
                            validate: {
                              required: (value) => {
                                if (!value && getValues("ApplyLink"))
                                  return "Please provide URL!";
                                return true;
                              },
                            },
                          })}
                          name="ApplyLinkURL"
                          placeholder="Enter link"
                        />
                        {errors.ApplyLinkURL && (
                          <p className="help is-danger">Please provide URL!</p>
                        )}
                      </div>
                      <div className="checkbox-subsection">
                        <div className="checkbox-message-section">
                          Choosing this option means a link to a page where an
                          applicant can apply is prefferred. (e.i. link to seek
                          or a custom company page for applicants).
                          <br />
                          <br />
                          The link will be visible to the applicants page.
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="checkbox-separator">
                    <label className="checkbox">
                      <input
                        {...register("ShowContactInformation")}
                        type="checkbox"
                        name="ShowContactInformation"
                        checked={showContactInformation}
                        onChange={onChangeShowContactInformation}
                      />
                      Send CV and Cover to Contact Person
                      <div className="checkbox-subsection">
                        <div className="checkbox-message-section">
                          By default a contact information section which will
                          include instruction to apply will be visible to the
                          applicant.
                          <br />
                          <br />
                          Uncheck to hide contact information from the
                          applicant.
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {/* End of field row */}
              <div className="submit-button-section">
                <button className="button is-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubmitJobListingPage;
