import React from "react";
import * as FaIcons from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { NavLink } from "react-router-dom";

const NavigationAdminComponent = () => {
  const navigate = useNavigate();

  const logout = () => {
    window.localStorage.removeItem("pkce");
    window.localStorage.removeItem("auth");
    navigate("/login", { replace: true });
  };

  const reRouteLink = (e: any, link: string) => {
    e.preventDefault();
    navigate(link, { replace: true });
  };

  return (
    <div className="app-sidebar-menu">
      <ul>
        <li>
          <NavLink
            to="job-management"
            className="mainmenu-heading"
            onClick={(e) => reRouteLink(e, "/job-management/job-listings")}
          >
            <FaIcons.FaBriefcase className="menu-icon" />{" "}
            <span>Job Management</span>
          </NavLink>
          <div className="submmenu-options">
            <NavLink
              to="job-management/job-listings"
              className="mainmenu-option"
            >
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>Job Listings</span>
            </NavLink>
            <NavLink
              to="job-management/add-job-listing"
              className="mainmenu-option"
            >
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>Add Job Listing</span>
            </NavLink>
            <NavLink
              to="job-management/invite-to-apply"
              className="mainmenu-option"
            >
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>Invite to Apply</span>
            </NavLink>
          </div>
        </li>
        <li>
          <NavLink
            to="settings"
            className="mainmenu-heading"
            onClick={(e) => reRouteLink(e, "/settings/add-category")}
          >
            <FaIcons.FaCog className="menu-icon" /> <span>Settings</span>
          </NavLink>
          <div className="submmenu-options">
            <NavLink to="settings/add-category" className="mainmenu-option">
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>Add Category</span>
            </NavLink>
            <NavLink to="settings/user-list" className="mainmenu-option">
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>User Admin</span>
            </NavLink>
            <NavLink to="settings/profile" className="mainmenu-option">
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>My Profile</span>
            </NavLink>
            <NavLink to="settings/settings-admin" className="mainmenu-option">
              <FaIcons.FaChevronRight className="submenu-icon" />
              <span>Default Admin</span>
            </NavLink>
          </div>
        </li>
        <li>
          <div
            className="mainmenu-heading navigator-hand-cursor"
            onClick={logout}
          >
            <FaIcons.FaSignOutAlt className="menu-icon" /> <span>Logout</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default NavigationAdminComponent;
